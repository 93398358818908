<mat-toolbar color="primary" class="mat-elevation-z4">
  <span>ETH Testnet Faucet</span>
  <span class="spacer"></span>
  <a href="https://github.com/rauljordan/eth-faucet" target="_blank">
    <button mat-button class="example-icon" aria-label="Example icon-button with share icon">
      View on Github <mat-icon>favorite</mat-icon>
    </button>
  </a>
</mat-toolbar>

<div class="progress-container">
<mat-progress-bar mode="indeterminate" color="warn" *ngIf="loading"></mat-progress-bar>
</div>

<mat-card class="example-card">
  <form [formGroup]="formGroup">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>Get Testnet ETH</mat-card-title>
      <mat-card-subtitle>Request ETH to your wallet address</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="transactionHash" class="results">
        <div>
          <a class="etherscan" href="https://eth.etherscan.io/tx/{{transactionHash}}" target="_blank">
            View on Etherscan
          </a>
        </div>
      </div>
      <div class="form-field-container">
        <mat-form-field appearance="fill">
          <mat-label>ETH Wallet Address</mat-label>
          <input matInput placeholder="0x..." name="walletAddress" formControlName="walletAddress">
          <mat-icon matSuffix>account_balance_wallet</mat-icon>
        </mat-form-field>
        <mat-error *ngIf="formGroup.dirty && formGroup.controls.walletAddress.hasError('required')">
          ETH address is required
        </mat-error>
      </div>
      <button mat-raised-button color="accent" (click)="submit($event)" [disabled]="loading">Request Funds</button>
    </mat-card-content>
  </form>
</mat-card>
